<template>
  <div class="middleware-simple-table-container">
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      row-key="id"
      :scroll="scroll"
      :row-class-name="() => 'pdf-split-flag'"
    >
      <template slot="name" slot-scope="text, record">
        <a-tooltip placement="topLeft" :title="text">
          <a @click="$refs.detailDrawer.show(record.id)">
            {{ text }}
          </a>
        </a-tooltip>
      </template>
      <template slot="middlewareType" slot-scope="record">
        {{ $t(`middleware_type.${record}`) }}
      </template>
      <template slot="status" slot-scope="text">
        <source-status-tag :status="text"></source-status-tag>
      </template>
    </a-table>

    <middleware-drawer ref="detailDrawer"></middleware-drawer>
  </div>
</template>

<script>
import MiddlewareDrawer from '@/components/drawer/MiddlewareDrawer'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'MiddlewareSimpleTable',
  components: {
    MiddlewareDrawer,
    SourceStatusTag
  },
  props: {
    dataSource: {
      type: Array
    },
    pagination: {
      type: [Boolean, Object],
      default: () => {
        return { pageSize: 5 }
      }
    },
    scroll: {
      type: Object,
      default: () => {
        return { x: scrollX }
      }
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: this.pagination === false ? false : 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'middleware_type',
          title: '中间件类型',
          width: 160,
          scopedSlots: {
            customRender: 'middlewareType'
          }
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 160,
          ellipsis: true
        },
        {
          dataIndex: 'status',
          align: 'center',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>

<style lang="less">
.middleware-simple-table-container {
  .ant-table {
    overflow: hidden;
    border-radius: 8px;
    .ant-table-content {
      .ant-table-placeholder {
        border: none;
      }
      .ant-table-thead > tr > th {
        height: 38px !important;
        padding: 0 16px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #f5f5f5;
        border-radius: 0;
      }
      .ant-table-tbody > tr {
        background: #fff;
        height: 46px;
        max-height: 46px;
        & > td {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          padding: 8px 16px;
          border-bottom: 1px solid #f5f5f5;
        }
      }
      .ant-table-fixed-columns-in-body:not([colspan]) > * {
        display: none;
      }
    }
  }

  .ant-pagination {
    .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item {
      width: 24px;
      height: 24px;
      line-height: 24px;
      min-width: 24px;
    }
  }
}
</style>
