<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="() => {
      visible = false
      baseInfoDisplay = false
    }"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link
                href="#alert"
                :title="`当前告警 (${count.alert})`"
              />
              <a-anchor-link
                href="#middleware"
                :title="`关联中间件 (${count.middleware})`"
              />
              <a-anchor-link
                href="#database"
                :title="`关联数据库 (${count.database})`"
              />
              <a-anchor-link href="#monitor" title="监控" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>
              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属操作系统">
                    {{ detail.os.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="IP 地址">
                    {{ detail.os.monitor_address }}
                  </a-descriptions-item>
                  <a-descriptions-item label="版本">
                    {{ detail.version }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属群组">
                    <a-tag v-for="group in detail.groups" :key="group.id">
                      {{ group.name }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag
                      :status="detail.status"
                    ></source-status-tag>
                  </a-descriptions-item>

                  <a-descriptions-item
                    v-if="detail.monitor_port && baseInfoDisplay"
                    label="监控端口"
                  >
                    {{ detail.monitor_port }}
                  </a-descriptions-item>
                  <a-descriptions-item
                    v-if="detail.monitor_url && baseInfoDisplay"
                    label="监控 URL"
                  >
                    {{ detail.monitor_url }}
                  </a-descriptions-item>
                  <a-descriptions-item v-if="detail.command && baseInfoDisplay" label="命令行">
                    {{ detail.command }}
                  </a-descriptions-item>
                  <a-descriptions-item label="监控状态" v-if="baseInfoDisplay">
                    <monitor-status-tag
                      v-for="item in monitorStatuses"
                      :key="item.name"
                      :status="item.value"
                      :name="item.name"
                    ></monitor-status-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="职能">
                    {{ detail.description }}
                  </a-descriptions-item>
                  <a-descriptions-item label="服务对象">
                    {{ detail.service_target }}
                  </a-descriptions-item>
                  <a-descriptions-item label="调用模式">
                    {{ detail.invocation_mode }}
                  </a-descriptions-item>
                  <a-descriptions-item label="数据同步内容">
                    {{ detail.data_exchange_content }}
                  </a-descriptions-item>
                  <a-descriptions-item label="数据同步周期">
                    {{ detail.data_exchange_period }}
                  </a-descriptions-item>
                </a-descriptions>
                <div style="text-align: center; font-size: 12px; color: #096DD9; margin-bottom: 16px;">
                  <a @click="baseInfoDisplay = !baseInfoDisplay">
                    <a-icon theme="filled" :type="baseInfoDisplay ? 'caret-up': 'caret-down'"></a-icon>
                  </a>
                </div>
              </div>
            </div>
            <div id="alert">
              <div class="small-module-title">
                <a-icon type="alert"></a-icon>
                <span> 当前告警 ({{ count.alert }})</span>
              </div>

              <div style="padding: 0 16px">
                <div style="height: 24px; margin-bottom: 24px">
                  <severity-radio-group
                    v-model="currentSeverity"
                    style="float: right"
                  ></severity-radio-group>
                </div>

                <div style="max-height: 400px; overflow: scroll">
                  <alert-timeline
                    :severity="currentSeverity"
                    :source-id="detail.id"
                    source-type="application"
                    @total="v => (count.alert = v)"
                    style="margin-top: 8px"
                  ></alert-timeline>
                </div>
              </div>
            </div>
            <div id="middleware">
              <div class="small-module-title">
                <a-icon type="block"></a-icon>
                <span> 关联中间件 ({{ count.middleware }})</span>
              </div>
              <div style="padding: 0 16px">
                <middleware-simple-table
                  :data-source="detail.middlewares"
                ></middleware-simple-table>
              </div>
            </div>
            <div id="database">
              <div class="small-module-title">
                <a-icon type="database"></a-icon>
                <span> 关联数据库 ({{ count.database }})</span>
              </div>
              <div style="padding: 0 16px">
                <database-simple-table
                  :data-source="detail.databases"
                ></database-simple-table>
              </div>
            </div>
            <div id="monitor">
              <div class="small-module-title">
                <a-icon type="line-chart"></a-icon>
                <span> 监控</span>
              </div>
              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>

                <a-row :gutter="16">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :sm="24"
                    :lg="12"
                    style="margin-bottom: 16px"
                  >
                    <monitor-chart-card
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="monitor" tab="监控指标">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="rule" tab="告警规则">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getApplication,
  getApplicationMonitorHistory,
  getApplicationMonitorStatusList,
  getApplicationMonitorItemList,
  getApplicationMonitorTriggerList,
  updateApplicationMonitorTrigger
} from '@/api/application'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import DatabaseSimpleTable from '@/components/table/DatabaseSimpleTable'
import MiddlewareSimpleTable from '@/components/table/MiddlewareSimpleTable'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'

export default {
  name: 'ApplicationDrawer',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    DatabaseSimpleTable,
    DatetimeRange,
    MiddlewareSimpleTable,
    MonitorChartCard,
    MonitorStatusTag,
    SeverityRadioGroup,
    SourceStatusTag,
    MonitorItemTable,
    MonitorTriggerTable
  },
  data () {
    return {
      visible: false,
      baseInfoDisplay: false,
      currentSeverity: 'all',
      detail: {
        id: '',
        name: '',
        os: {
          id: '',
          name: '',
          monitor_address: ''
        },
        version: '',
        monitor_port: '',
        monitor_url: '',
        command: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown',
        middlewares: [],
        databases: [],
        description: '',
        service_target: '',
        invocation_mode: '',
        data_exchange_content: '',
        data_exchange_period: ''
      },
      monitorStatuses: [],
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      historyFunc: getApplicationMonitorHistory,
      getItemListFunc: getApplicationMonitorItemList,
      getTriggerFunc: getApplicationMonitorTriggerList,
      updateFunc: updateApplicationMonitorTrigger,
      count: {
        middleware: 0,
        database: 0,
        alert: 0
      }
    }
  },
  computed: {
    monitorItems () {
      return [
        {
          key: `net.tcp.listen[${this.detail.monitor_port}]`,
          title: '端口状态'
        },
        {
          key: `web.page.perf[${this.detail.monitor_url}]`,
          title: '页面加载时间 (秒)'
        },
        {
          key: `proc.num[,,,${this.detail.command}]`,
          title: '进程数量'
        }
      ]
    }
  },
  methods: {
    show (id) {
      getApplication(id)
        .then(res => {
          this.detail = res.data
          this.count.middleware = this.detail.middlewares.length
          this.count.database = this.detail.databases.length
        })
        .finally(() => {
          this.visible = true
        })
      getApplicationMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
    }
  }
}
</script>
